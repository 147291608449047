import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dot from "../../components/dot"

// Styles
import "../../css/pages/work.css"

// images
import screens from "../../images/tikkle/screens.png"


const TikklePage = () => (
  <Layout>
    <SEO title="MPX" />
    <section className="tikkle-banner work-banner bg-black h-64 py-20 px-4 lg:px-0 text-white">
      <div className="container flex flex-col h-full justify-between">
        <div className="flex flex-col justify-around h-full">
          <h2 className="font-header">Case study <Dot/></h2>
          <div>
            <h1 className="font-bold">Tikkle</h1>
            <p className="text-white mt-5 text-shadow">A mobile app for an enterprise bridging the gap on all <br/>the heirarchys in an organization.</p>
          </div>
        </div>
      </div>
    </section>

    <div className="container pt-16 px-4 lg:px-0 flex flex-col sm:flex-row justify-between">
      <div className="font-serif flex justify-between sm:justify-start">
        <span className="font-bold font-header">date :</span> &nbsp;&nbsp;<i>March 2017 - November 2017</i>
      </div>
      <div className="font-serif flex justify-between sm:justify-start mt-4 sm:mt-0">
        <span className="font-bold font-header">Role:</span> &nbsp;&nbsp;<i>Product designer</i>
      </div>
    </div>

    <section className="container pt-16 px-4 lg:px-0">
      <h4 className="font-header mb-5">brief <Dot/></h4>
      <p>People spend a lot of time on mobile. With data getting cheaper and faster(well at least in India), we can leverage it for employee engagement in an organization. Tikkle was focused on employee engagement and mobile learning app by a parent company called engage4more. It’s a mobile app to communicate and upgrade the employees, remotely and consistently.
      <br/><br/>
      The branding for Tikkle was done by their Senior Art Director and my <i>goal of this project was to design and carry out the same branding and aesthetics across their products which in this case was their mobile app.</i></p>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">Target Audience <Dot/></h4>
      <p>Employees of a medium to large scale organization or a company.<br/>There would be two age groups</p>
      <ul>
        <li>25-40 - Easy to interact with complex interfaces</li>
        <li>40-60 - Difficult to interact with complex interfaces</li>
			</ul>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">empathizing with the audience <Dot/></h4>
      <p>I had a small chat with each of my target audience group to get a sense of what they feel to be in a company and asked for the pain points revolving around tikkle. Here are some
        of my observations after empathizing with them.
      </p>
      <ul>
        <li>There was a lot of back and forth time spent in email interactions to get things done in an organization.</li>
        <li>There was a lack of social circle among employees in different departments.</li>
        <li>People found very hard to voice their opinion and keep track of them on the go.</li>
        <li>Lot of people in the 25-40 age group had resorted to personal medium like Whatsapp etc for quick communications which came accross as invading their
          personal space.
        </li>
			</ul>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">Key Engagements <Dot/></h4>
      <p>
      With the help of my client, key engagements in a company via Tikkle were identified as described below
      </p>
    </section>

    <section className="container lg text-center px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569329971/portfolio/ico8i3xfxajkoahpcugy.png" alt="Key engagements" className="mb-5" />
      <p className="font-header text-xs">Overhead sketch of key engagements</p>
    </section>

    <section className="container py-20 px-4 lg:px-0">
      <ul><strong>Feed(Buzz)</strong>: Newsfeed of posts from various heads posted through Tikkle's CMS platform in the web</ul>
      <ul><strong>Chats</strong>: Communication between teams and fellow employees</ul>
      <ul><strong>Quizzes</strong>: A method to help employees to keep up with the current trends and markets</ul>
      <ul><strong>Surveys</strong>: Feedback regarding organization events etc</ul>
      <ul><strong>Training(iCan)</strong>: Education slides to have regular inflow of knowledge</ul>
      <ul><strong>Live streams</strong>: Interactions between the heads of the organization with their employees</ul>
    </section>

    <section className="container lg text-center px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569330318/portfolio/jdyt4gfpeogp6pg3tsaq.png" alt="engagements attribute" className="mb-5" />
      <p className="font-header text-xs">Some of the attributes for the key engagements</p>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <p>I started to understand each engagement and map out its attributes. These identifications gave me a virtual image and a mental model of what elements the mobile app needed.</p>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">Understanding the space <Dot/></h4>
      <p>Before digging into the wireframes and designs, I wanted to understand what other companies were doing in this space. I had to resort to Google for my target audience and competitors like Facebook for Workplace and Microsoft Kaizala.
      <br/><br/>Due to the limited time, I worked with my client and was able to identify some of the user needs and behaviors which they had done during their branding phase.
      </p>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">Initial ideations  <Dot/></h4>
      <p>Now, knowing the end-users and the space I'm building in, I asked myself the following questions</p>
      <ul>
        <li>Will user want yet another platform similar to other social networks?</li>
        <li>What value will this give to the employees in an organization?</li>
      </ul>
      <p>I knew that most of the employees in a medium to large scale organization feel that they might not be able to voice their opinion at a large scale. By making this platform as invitefull, easy to use and resourceful, It can help employees to voice their opinions, gain knowledge and engage with the organization in a much better way compared to the conventional emails.</p>
      <p>I started visualizing the content to be consumed in the form of cards which would be the main elements of the app.</p>
    </section>

    <section className="container pt-12 lg text-center px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569330762/portfolio/pzgfrhedfzpkzhspgkna.png" alt="card wireframe" className="mb-5" />
      <p className="font-header text-xs">various types of content cards wireframes</p>
    </section>

    <section className="container pt-12 px-4 lg:px-0">
      <p>One thing I had in mind was not to reinvent the content curation/consumption wheel since it would share the same space to that of Facebook and other social networking apps. I didn't want users to feel the need to learn and get used to another app.</p>
    </section>

    <section className="container pt-12 lg text-center px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569330889/portfolio/lnkmuj67j9b8dzbuiv3g.png" alt="app wireframes" className="mb-5" />
      <p className="font-header text-xs">Some of the initial wireframes done through client discussions</p>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">Designs  <Dot/></h4>
      <p>After observing the branding guidelines set by the Senior Art Director, I played with the interfaces, iterated, implemented the feedback, re-iterated. I  really liked how the brand included vibrant colors which were part of Tikkle's ethos i.e make work fun and not stressful. I explored gradient masks for the images, color popping elements and kept it simple, familiar and easy to use.</p>
    </section>

    <img src={screens} alt="app screens" className="my-24" />

    <section className="bg-black-gradient pt-16 md:pt-24 px-4 lg:px-0">
      <div className="container">
        <h4 className="font-header mb-5">Challenges <Dot/></h4>
        <p>The engagements included people to use quizzes, surveys, education modules. But how can we drive more of these engagements to the employees?
        <br/><br/>
        The client suggested to have the training, surveys, livestreams, modules etc onto a separate tab called iCan. But there were no motives for users to go to the iCan screen. Since I had the idea of implementing cards approach for the feed(Buzz) as a central way to consume the content, it would be intuitive to introduce various types of cards apart from the text, photo, videos, articles etc in the feed(Buzz).
        </p>
      </div>
    </section>

    <section className="mt-16 pt-16 text-center px-4 lg:px-0">
      <div className="container lg">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569332922/portfolio/tikkle_carddesigns.png" alt="app wireframes" className="mb-5" />
        <p className="font-header text-xs">Some of card designs visualized to drive user engagements through the feed</p>
      </div>
    </section>

    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">Key Takeaways <Dot/></h4>
      <p>This was one of the most detailed projects I've worked on since there were many screens involving different use cases which would help the developer build without any hindrance.</p>
      <ul>
        <li>I designed almost 150+ screens including iOS and Android platforms in this project which was the first for me and the main thing I learned is to manage the entire project seamlessly by building a mini design system through symbols/components and text styles helping me to quickly change designs based on feedback and iteration across the screens. </li>
        <li>Learned how to use generic UI patterns and elements to save time since the budget and time were a constrained.</li>
        <li>Learned to carry out the branding defined earlier by the company and to bring out the same aesthetics accross the app</li>
      </ul>
      <p>
      All in all, there was a challenge on keeping the designs consistent with the brand guidlines and bring in the brand ethos which I felt could do better after reviewing the project later. But hey, will designers always be fine with their previous work?
      </p>
    </section>




    <section className="container mt-16 mb-32 flex justify-between px-4 lg:px-0">
      <Link to="/work/ethsurvey" className="line">
        previous project
      </Link>
      <Link to="/work/subtitlesforyoutube" className="line">
        next project
      </Link>
    </section>

  </Layout>
)

export default TikklePage
